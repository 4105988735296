@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  background: #fff;
  border-radius: 15px;
  position: relative;
  @media screen and (max-width: vars.$big) {
    width: 80vw;
    max-height: 80vh;
    border-radius: adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
  }
}
