@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  color: vars.$white;
  position: relative;
  max-height: 50vh;
  width: 100%;
  background: vars.$white;
  margin-right: 2px;
  margin-bottom: 2px;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  @media screen and (max-width: vars.$big) {
    border-radius: adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    border-radius: adaptSize(15px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    border-radius: adaptSize(10px, vars.$small);
  }
}
