@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

$outline_width: 2px;

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  color: vars.$blue;
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
    overflow: hidden;
  }
}

.line {
  padding-bottom: 30px;
  border-bottom: 1px solid vars.$blue;
  @media screen and (max-width: vars.$big) {
    padding-bottom: adaptSize(30px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    padding-bottom: adaptSize(30px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    border-bottom: none;
    padding-bottom: 0px;
    padding-right: adaptSize(20px, vars.$small);
    border-right: 1px solid vars.$blue;
  }
}

.name {
  color: vars.$black;
  grid-column: 1 / -1;
  font-size: 50px;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(50px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(40px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(30px, vars.$small);
  }
}

.count {
  font-size: 100px;
  @media screen and (max-width: vars.$big) {
    font-size: adaptSize(120px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    font-size: adaptSize(100px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    font-size: adaptSize(60px, vars.$small);
  }
}

.icon {
  width: 80px;
  justify-self: flex-end;
  @media screen and (max-width: vars.$big) {
    width: adaptSize(80px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    width: adaptSize(80px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    width: adaptSize(40px, vars.$small);
  }
}

.outlined {
  display: block;
  text-shadow: vars.$blue(-$outline_width) (-$outline_width) 0px,
    vars.$blue $outline_width(-$outline_width) 0px,
    vars.$blue (-$outline_width) $outline_width 0px,
    vars.$blue $outline_width $outline_width 0px;
  color: vars.$white;
}
