@use "../styles/vars.module" as vars;
@import "../styles/functions.scss";

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    grid-template-columns: 1fr;
  }
}

.counter {
  align-self: flex-start;
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
    width: 100%;
  }
}

.crops {
  grid-column: 1 / -1;
}
