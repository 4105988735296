@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";
@import "../../styles/fonts.scss";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    transform: translateY(-5%);
  }
  @media screen and (max-width: vars.$big) {
    border-radius: adaptSize(5px, vars.$big);
    margin-bottom: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    border-radius: adaptSize(5px, vars.$medium);
    margin-bottom: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    border-radius: adaptSize(5px, vars.$small);
    margin-bottom: adaptSize(10px, vars.$small);
  }
}

.img-container {
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  & > svg {
    height: 200px;
  }
  @media screen and (max-width: vars.$big) {
    height: adaptSize(200px, vars.$big);
    padding: adaptSize(20px, vars.$big);
    & > svg {
      height: adaptSize(200px, vars.$big);
    }
  }

  @media screen and (max-width: vars.$medium) {
    height: adaptSize(120px, vars.$medium);
    padding: adaptSize(20px, vars.$medium);
    & > svg {
      height: adaptSize(120px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    height: adaptSize(150px, vars.$small);
    padding: adaptSize(20px, vars.$small);
    & > svg {
      height: adaptSize(150px, vars.$small);
    }
  }
}

.info {
  background: vars.$blue;
  color: vars.$white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;
  }
  & > div:first-child {
    margin-bottom: 10px;
  }
  @extend .T2;
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(10px, vars.$big);
    border-radius: 0 0 adaptSize(5px, vars.$big) adaptSize(5px, vars.$big);
    & > div:first-child {
      margin-bottom: adaptSize(10px, vars.$big);
    }
  }

  @media screen and (max-width: vars.$medium) {
    padding: adaptSize(10px, vars.$medium);
    border-radius: 0 0 adaptSize(5px, vars.$medium) adaptSize(5px, vars.$medium);
    & > div:first-child {
      margin-bottom: adaptSize(10px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    padding: adaptSize(10px, vars.$small);
    border-radius: 0 0 adaptSize(5px, vars.$small) adaptSize(5px, vars.$small);
    & > div:first-child {
      margin-bottom: adaptSize(10px, vars.$small);
    }
  }
}

.info-title {
  font-weight: 500;
}

.dark {
  background: vars.$black;
}
