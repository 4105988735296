@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  max-height: 90svh;
  max-width: 200px;
  padding: 20px 10px;
  margin-bottom: 20px;
  border-radius: 0 15px 15px 0;
  background-color: vars.$white;
  color: vars.$blue;
  @media screen and (max-width: vars.$big) {
    max-width: adaptSize(300px, vars.$big);
    padding: adaptSize(20px, vars.$big) adaptSize(10px, vars.$big);
    margin-bottom: adaptSize(20px, vars.$big);
    border-radius: 0 adaptSize(15px, vars.$big) adaptSize(15px, vars.$big) 0;
  }

  @media screen and (max-width: vars.$medium) {
    max-width: adaptSize(300px, vars.$medium);
    padding: adaptSize(20px, vars.$medium) adaptSize(10px, vars.$medium);
    margin-bottom: adaptSize(20px, vars.$medium);
    border-radius: 0 adaptSize(15px, vars.$medium) adaptSize(15px, vars.$medium)
      0;
  }

  @media screen and (max-width: vars.$small) {
    max-width: adaptSize(250px, vars.$small);
    padding: adaptSize(20px, vars.$small) adaptSize(10px, vars.$small);
    margin-bottom: adaptSize(20px, vars.$small);
    border-radius: 0 adaptSize(15px, vars.$small) adaptSize(15px, vars.$small) 0;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
  }
}

.puller {
  position: absolute;
  right: -39px;
  top: 50%;
  transform: translateY(-50%);
  background: vars.$white;
  padding: 10px;
  border-radius: 0 15px 15px 0;
  box-shadow: 8px 4px 10px 0px rgba(34, 60, 80, 0.2);
  & > svg {
    width: 20px;
  }
  @media screen and (max-width: vars.$big) {
    right: adaptSize(-60px, vars.$big);
    padding: adaptSize(10px, vars.$big);
    border-radius: 0 adaptSize(15px, vars.$big) adaptSize(15px, vars.$big) 0;
    box-shadow: adaptSize(8px, vars.$big) adaptSize(4px, vars.$big)
      adaptSize(10px, vars.$big) 0px rgba(34, 60, 80, 0.2);
    & > svg {
      width: adaptSize(40px, vars.$big);
    }
  }
  @media screen and (max-width: vars.$medium) {
    right: adaptSize(-35px, vars.$medium);
    padding: adaptSize(10px, vars.$medium);
    border-radius: 0 adaptSize(15px, vars.$medium) adaptSize(15px, vars.$medium)
      0;
    box-shadow: adaptSize(8px, vars.$medium) adaptSize(4px, vars.$medium)
      adaptSize(10px, vars.$medium) 0px rgba(34, 60, 80, 0.2);
    & > svg {
      width: adaptSize(20px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    right: adaptSize(-35px, vars.$small);
    padding: adaptSize(10px, vars.$small);
    border-radius: 0 adaptSize(15px, vars.$small) adaptSize(15px, vars.$small) 0;
    box-shadow: adaptSize(8px, vars.$small) adaptSize(4px, vars.$small)
      adaptSize(10px, vars.$small) 0px rgba(34, 60, 80, 0.2);
    & > svg {
      width: adaptSize(20px, vars.$small);
    }
  }
}

.add-camera {
  background: vars.$blue;
  color: vars.$white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  align-items: center;
  border: 1px solid vars.$blue;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    background: vars.$white;
    color: vars.$blue;
  }
  & > svg {
    width: 40px;
  }
  @media screen and (max-width: vars.$big) {
    border-radius: adaptSize(15px, vars.$big);
    padding: adaptSize(10px, vars.$big);
    gap: adaptSize(10px, vars.$big);
    & > svg {
      width: adaptSize(50px, vars.$big);
    }
  }

  @media screen and (max-width: vars.$medium) {
    border-radius: adaptSize(15px, vars.$medium);
    padding: adaptSize(10px, vars.$medium);
    gap: adaptSize(10px, vars.$medium);
    & > svg {
      width: adaptSize(30px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    border-radius: adaptSize(15px, vars.$small);
    padding: adaptSize(20px, vars.$small);
    gap: adaptSize(10px, vars.$small);
    height: adaptSize(200px, vars.$small);
    & > svg {
      width: adaptSize(40px, vars.$small);
    }
  }
}
