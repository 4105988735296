@use "../../../styles/vars.module" as vars;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-img {
  max-height: 200px;
}

.video-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  & > svg {
    max-height: 50vh;
  }
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
    & > svg {
      max-height: 50vh;
    }
  }
}
