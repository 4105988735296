@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 20px 0;
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(20px, vars.$big) 0;
  }

  @media screen and (max-width: vars.$medium) {
    padding: adaptSize(20px, vars.$medium) 0;
  }

  @media screen and (max-width: vars.$small) {
    padding: adaptSize(15px, vars.$small) 0;
  }
}

.logo {
  justify-self: flex-start;
}

.lang-container {
  display: flex;
  gap: 15px;
  padding-top: 10px;
  align-items: flex-start;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(15px, vars.$big);
    padding-top: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(15px, vars.$medium);
    padding-top: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(15px, vars.$small);
    padding-top: 0;
  }
}

.info-icon {
  cursor: pointer;
  &:hover {
    color: vars.$blue2;
  }
}

.description {
  grid-column: 1 / -1;
}
