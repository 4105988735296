@use "./styles/vars.module" as vars;
@import "./styles/fonts.scss";
@font-face {
  font-family: Suisse Intl;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
  font-family: Suisse Intl;
  font-weight: 500;
  font-display: swap;
  src: url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff2") format("woff2"),
    url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff") format("woff");
}

* {
  font-family: "Suisse Intl", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #d8e3ec;
  color: vars.$black;
}

.app-container {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 10px;
  width: 100%;
  min-height: 100svh;
  height: 100%;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
  }
}

.bottom-section {
  align-self: flex-end;
}

.max-width {
  max-width: vars.$max_width;
  justify-self: center;
  width: 100%;
  padding: 0 10px;
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
    padding: 0 20px;
  }
}
