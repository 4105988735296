@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: vars.$white;
  color: vars.$blue;
  width: 100%;
  grid-column: 1 / -1;
}

.img {
  display: block;
  width: 100%;
  max-height: 100px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
  border: 1px solid vars.$blue;
  border-bottom: none;
  border-radius: 15px 15px 0 0;
  @media screen and (max-width: vars.$big) {
    max-height: adaptSize(140px, vars.$big);
    border-radius: adaptSize(5px, vars.$big) adaptSize(5px, vars.$big) 0 0;
    border-radius: adaptSize(15px, vars.$big) adaptSize(15px, vars.$big) 0 0;
  }

  @media screen and (max-width: vars.$medium) {
    max-height: adaptSize(100px, vars.$medium);
    border-radius: adaptSize(5px, vars.$medium) adaptSize(5px, vars.$medium) 0 0;
    border-radius: adaptSize(15px, vars.$medium) adaptSize(15px, vars.$medium) 0
      0;
  }

  @media screen and (max-width: vars.$small) {
    max-height: adaptSize(150px, vars.$small);
    border-radius: adaptSize(5px, vars.$small) adaptSize(5px, vars.$small) 0 0;
    border-radius: adaptSize(15px, vars.$small) adaptSize(15px, vars.$small) 0 0;
  }
}

.info {
  display: grid;
  width: 100%;
  gap: 10px;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  border: 1px solid vars.$blue;
  border-top: none;
  border-radius: 0 0 15px 15px;
  & > span {
    grid-column: 1 / -1;
  }
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
    padding: adaptSize(10px, vars.$big);
    border-radius: 0 0 adaptSize(15px, vars.$big) adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
    padding: adaptSize(10px, vars.$medium);
    border-radius: 0 0 adaptSize(15px, vars.$medium)
      adaptSize(15px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
    padding: adaptSize(10px, vars.$small);
    border-radius: 0 0 adaptSize(15px, vars.$small) adaptSize(15px, vars.$small);
  }
}

.icons {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
}

.icon {
  transition: all 0.5s ease;
  &:hover {
    color: vars.$black;
  }
  &:first-child {
    width: 20px;
  }
  &:last-child {
    width: 15px;
  }
  @media screen and (max-width: vars.$big) {
    &:first-child {
      width: adaptSize(30px, vars.$big);
    }
    &:last-child {
      width: adaptSize(20px, vars.$big);
    }
  }

  @media screen and (max-width: vars.$medium) {
    &:first-child {
      width: adaptSize(20px, vars.$medium);
    }
    &:last-child {
      width: adaptSize(15px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    &:first-child {
      width: adaptSize(20px, vars.$small);
    }
    &:last-child {
      width: adaptSize(15px, vars.$small);
    }
  }
}

.selected {
  & > .info {
    background: vars.$blue;
    color: vars.$white;
  }
}
