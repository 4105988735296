@use "../../../styles/vars.module" as vars;
@import "../../../styles/functions.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(20px, vars.$small);
  }
}

.add-roi-btn {
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
    display: none;
  }
}
