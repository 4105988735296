@use "../../styles/vars.module" as vars;
@import "../../styles/fonts.scss";
@import "../../styles/functions.scss";

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: vars.$white;
  border-radius: 15px;
  overflow: auto;
  max-height: 90vh;
  color: vars.$blue;
  gap: 10px;
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(20px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
    gap: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    padding: adaptSize(20px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    padding: adaptSize(20px, vars.$small);
    border-radius: adaptSize(15px, vars.$small);
    gap: adaptSize(10px, vars.$small);
  }
}

.error {
  color: red;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
  }
}
