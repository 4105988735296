@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: vars.$white;
  width: 340px;
  gap: 30px;
  border-radius: 15px;
  padding: 10px 15px;
  @media screen and (max-width: vars.$big) {
    width: adaptSize(450px, vars.$big);
    gap: adaptSize(30px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
    padding: adaptSize(10px, vars.$big) adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    width: adaptSize(300px, vars.$medium);
    gap: adaptSize(30px, vars.$medium);
    border-radius: adaptSize(15px, vars.$medium);
    padding: adaptSize(10px, vars.$medium) adaptSize(15px, vars.$medium);
  }
  @media screen and (max-width: vars.$small) {
    width: adaptSize(300px, vars.$small);
    gap: adaptSize(20px, vars.$small);
    border-radius: adaptSize(15px, vars.$small);
    padding: adaptSize(10px, vars.$small) adaptSize(15px, vars.$small);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.button {
  display: flex;
  color: vars.$blue;
  gap: 5px;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: vars.$blue2;
  }
  & > svg {
    height: 20px;
  }
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(5px, vars.$big);
    padding: adaptSize(5px, vars.$big);
    border-radius: adaptSize(5px, vars.$big);
    & > svg {
      height: adaptSize(20px, vars.$big);
    }
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(5px, vars.$medium);
    padding: adaptSize(5px, vars.$medium);
    border-radius: adaptSize(5px, vars.$medium);
    & > svg {
      height: adaptSize(20px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(5px, vars.$small);
    padding: adaptSize(5px, vars.$small);
    border-radius: adaptSize(5px, vars.$small);
    & > svg {
      height: adaptSize(20px, vars.$small);
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    grid-column: 1 / -1;
    gap: adaptSize(10px, vars.$small);
  }
}
