@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  background: vars.$white;
  height: 90svh;
  border-radius: 15px;
  overflow: auto;
  padding: 10px 0;
  @media screen and (max-width: vars.$big) {
    border-radius: adaptSize(15px, vars.$big);
    padding: adaptSize(10px, vars.$big) 0;
  }

  @media screen and (max-width: vars.$medium) {
    border-radius: adaptSize(15px, vars.$medium);
    padding: adaptSize(10px, vars.$medium) 0;
  }

  @media screen and (max-width: vars.$small) {
    border-radius: adaptSize(15px, vars.$small);
    padding: adaptSize(10px, vars.$small) 0;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  min-width: 800px;
  border-radius: 15px;
  @media screen and (max-width: vars.$big) {
    min-width: adaptSize(800px, vars.$big);
    padding: adaptSize(20px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    min-width: adaptSize(800px, vars.$medium);
    padding: adaptSize(20px, vars.$medium);
    border-radius: adaptSize(15px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    min-width: auto;
    padding: adaptSize(10px, vars.$small);
    border-radius: adaptSize(15px, vars.$small);
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(20px, vars.$small);
    width: 100%;
  }
}

.buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 0;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(10px, vars.$big);
    padding: adaptSize(10px, vars.$big) 0;
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
    padding: adaptSize(10px, vars.$medium) 0;
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
    padding: adaptSize(10px, vars.$small) 0;
  }
}

.error {
  color: red;
}

.saved {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: vars.$blue;
  color: vars.$white;
  padding: 20px 40px;
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  transition: all ease 0.3s;
  gap: 10px;
  box-shadow: 8px 4px 10px 0px rgba(34, 60, 80, 0.2);

  & > svg {
    width: 40px;
  }
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(20px, vars.$big) adaptSize(40px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
    gap: adaptSize(10px, vars.$big);
    & > svg {
      width: adaptSize(40px, vars.$big);
    }
  }

  @media screen and (max-width: vars.$medium) {
    padding: adaptSize(20px, vars.$medium) adaptSize(40px, vars.$medium);
    border-radius: adaptSize(15px, vars.$medium);
    gap: adaptSize(10px, vars.$medium);
    & > svg {
      width: adaptSize(40px, vars.$medium);
    }
  }

  @media screen and (max-width: vars.$small) {
    padding: adaptSize(20px, vars.$small) adaptSize(40px, vars.$small);
    border-radius: adaptSize(15px, vars.$small);
    gap: adaptSize(10px, vars.$small);
    & > svg {
      width: adaptSize(40px, vars.$small);
    }
  }
}
