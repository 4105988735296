@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  background: vars.$white;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 15px;
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(20px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
  }
}

.form-name {
  padding-bottom: 10px;
  @media screen and (max-width: vars.$big) {
    padding-bottom: adaptSize(10px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
  }
}

.buttons-container {
  display: flex;
  gap: 20px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
  }
}

.error {
  margin-bottom: 20px;
  @media screen and (max-width: vars.$big) {
    margin-bottom: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
  }
}
