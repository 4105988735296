@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: vars.$blue;
  background: vars.$blue2;
  padding: 20px;
  border: 1px solid vars.$blue;
  border-radius: 15px;
  gap: 20px;
  @media screen and (max-width: vars.$big) {
    padding: adaptSize(20px, vars.$big);
    border-radius: adaptSize(15px, vars.$big);
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    padding: adaptSize(20px, vars.$medium);
    border-radius: adaptSize(15px, vars.$medium);
    gap: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    padding: adaptSize(20px, vars.$small);
    border-radius: adaptSize(15px, vars.$small);
    gap: adaptSize(20px, vars.$small);
  }
}

.icon {
  width: 40px;
  @media screen and (max-width: vars.$big) {
    width: adaptSize(80px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    width: adaptSize(80px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    width: adaptSize(40px, vars.$small);
  }
}
