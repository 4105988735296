@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  padding: 20px 0;
  gap: 20px;
  overflow: auto;
  @media screen and (max-width: vars.$big) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(adaptSize(120px, vars.$big), 1fr)
    );
    padding: adaptSize(20px, vars.$big) 0;
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(adaptSize(80px, vars.$medium), 1fr)
    );
    padding: adaptSize(20px, vars.$medium) 0;
    gap: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(adaptSize(80px, vars.$small), 1fr)
    );
    padding: adaptSize(20px, vars.$small) 0;
    gap: adaptSize(20px, vars.$small);
  }
}
