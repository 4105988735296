@use "../../styles/vars.module" as vars;
@import "../../styles/fonts.scss";

.container {
  max-width: 730px;
  overflow: hidden;
  margin-top: 20px;
  @extend .T1;
  @media screen and (max-width: vars.$big) {
    margin-top: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    margin-top: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    max-width: none;
    width: 100%;
    margin-top: adaptSize(20px, vars.$small);
  }
}

.bullets {
  padding-top: 10px;
  & > li {
    padding-bottom: 10px;
    padding-left: 15px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 6px;
      height: 6px;
      background: currentColor;
      border-radius: 50%;
    }
  }
  @media screen and (max-width: vars.$big) {
    padding-top: adaptSize(10px, vars.$big);
    & > li {
      padding-bottom: adaptSize(10px, vars.$big);
      padding-left: adaptSize(15px, vars.$big);
      &::after {
        top: adaptSize(10px, vars.$big);
        width: adaptSize(8px, vars.$big);
        height: adaptSize(8px, vars.$big);
      }
    }
  }

  @media screen and (max-width: vars.$medium) {
    padding-top: adaptSize(5px, vars.$medium);
    & > li {
      padding-bottom: adaptSize(5px, vars.$medium);
      padding-left: adaptSize(10px, vars.$medium);
      &::after {
        top: adaptSize(10px, vars.$medium);
        width: adaptSize(5px, vars.$medium);
        height: adaptSize(5px, vars.$medium);
      }
    }
  }

  @media screen and (max-width: vars.$small) {
    padding-top: adaptSize(5px, vars.$small);
    & > li {
      padding-bottom: adaptSize(5px, vars.$small);
      padding-left: adaptSize(10px, vars.$small);
      &::after {
        top: adaptSize(10px, vars.$small);
        width: adaptSize(5px, vars.$small);
        height: adaptSize(5px, vars.$small);
      }
    }
  }
}
