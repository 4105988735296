@use "../../../styles/vars.module" as vars;

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid vars.$blue;

  &-dark {
    background: vars.$blue;
  }
}
