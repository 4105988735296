@use "../../styles/vars.module" as vars;
@import "../../styles/functions.scss";
@import "../../styles/fonts.scss";

.container {
  display: inline-flex;
  flex-direction: column;
  text-decoration: none;
  color: vars.$black;
  & > span {
    font-size: 12px;
    margin-top: 10px;
  }
  @media screen and (max-width: vars.$big) {
  }

  @media screen and (max-width: vars.$medium) {
  }

  @media screen and (max-width: vars.$small) {
    & > span {
      margin-top: adaptSize(5px, vars.$small);
    }
  }
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: vars.$big) {
    gap: adaptSize(20px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    gap: adaptSize(10px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    gap: adaptSize(10px, vars.$small);
  }
}

.logo {
  width: 120px;
  height: auto;
  color: vars.$black;
  @media screen and (max-width: vars.$big) {
    width: adaptSize(160px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    width: adaptSize(120px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    width: adaptSize(80px, vars.$small);
  }
}

.info-icon {
  cursor: pointer;
  width: 20px;
  &:hover {
    color: vars.$blue;
  }
  @media screen and (max-width: vars.$big) {
    width: adaptSize(25px, vars.$big);
  }

  @media screen and (max-width: vars.$medium) {
    width: adaptSize(20px, vars.$medium);
  }

  @media screen and (max-width: vars.$small) {
    width: adaptSize(15px, vars.$small);
  }
}
